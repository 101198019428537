$_fh_color_blue: #89a4cb;
$_link_color: #0f1245;
$_link_hover_color: #0c4f91;
$lg-media:991.98px;
$md-media:767.98px;
$xs-media:575.98px;
@mixin lg-width() {
    @media (max-width: $lg-media) {
        @content;
    }
}

@mixin md-width() {
    @media (max-width: $md-media) {
        @content;
    }
}

@mixin xs-width() {
    @media (max-width: $xs-media) {
        @content;
    }
}

@import "~bootstrap/dist/css/bootstrap.css";
//@import url(http://fonts.googleapis.com/earlyaccess/notosanstc.css);
@font-face {
    font-family: 'MyCustomFont';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Light.woff2) format('woff2'), url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Light.woff) format('woff');
}

@font-face {
    font-family: 'MyCustomFont';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Regular.woff2) format('woff2'), url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Regular.woff) format('woff'), url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'MyCustomFont';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Medium.woff2) format('woff2'), url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Medium.woff) format('woff'), url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Medium.otf) format('opentype');
}

@font-face {
    font-family: 'MyCustomFont';
    font-style: normal;
    font-weight: 300;
    src: local('Barlow Light'), local('Barlow-Light'), url(https://fonts.gstatic.com/s/barlow/v1/7cHqv4kjgoGqM7E3p-ks51ostz0rdg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'MyCustomFont';
    font-style: normal;
    font-weight: 400;
    src: local('Barlow Regular'), local('Barlow-Regular'), url(https://fonts.gstatic.com/s/barlow/v1/7cHpv4kjgoGqM7E_DMs5ynghnQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'MyCustomFont';
    font-style: normal;
    font-weight: 500;
    src: local('Barlow Medium'), local('Barlow-Medium'), url(https://fonts.gstatic.com/s/barlow/v1/7cHqv4kjgoGqM7E3_-gs51ostz0rdg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body,
html {
    min-height: 75rem;
    background-color: #ffffff;
    line-height: 1.5;
    color: #333333;
    font-family: 'MyCustomFont', sans-serif; //font-family: 'Barlow', 'Noto Sans TC', 'Raleway', 'Microsoft Jhenghei', '微軟正黑體', sans-serif; 
    font-size: 16px;
}

body *,
html * {
    font-family: 'MyCustomFont', sans-serif;
}

html {
    .isCht {
        letter-spacing: 3px;
        line-height: 1.7;
        font-size: 14px;
    }
}

.no-letter-space {
    letter-spacing: 0px !important;
}

.popover-header {
    color: #333 !important;
}

.arrow-btn {
    width: 70px;
    height: 60px;
    background-color: $_fh_color_blue;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    cursor: pointer;
}

.inner-btn {
    position: absolute;
    display: flex;
    left: 0;
    bottom: 0;
}

.inner-btn-mobile {
    position: relative;
    display: flex;
    width: 560px;
    margin-right: 40px;
    margin-left: auto;
    @include md-width {
        width: 100%;
        margin-left: 40px;
    }
}

.bg-flower {
    background: url(assets/images/flower_bg_01.jpg);
    background-position: top left;
    background-repeat: repeat-y;
}

.bg-flower-2 {
    background: url(assets/images/flower_bg_02.jpg);
    background-position: top right;
    background-repeat: repeat-y;
    @include md-width {
        background-size: 100%;
    }
}

.bg-price-table {
    background: url(assets/images/Price_table_bg_remodel.jpg);
    background-position: top right;
    background-repeat: no-repeat;
    background-color: #E8EFF9;
    @include md-width {
        background-size: 150%;
    }
}

.bg-right-box {
    background: url(assets/images/box-bg-right.jpg);
    background-repeat: no-repeat;
    background-position: top right;
    background-color: #ffffff;
    @include md-width {
        background-size: 100%;
    }
}

.bg-left-box {
    background: url(assets/images/box-bg-left.jpg);
    background-repeat: no-repeat;
    background-position: top left;
    background-color: #ffffff;
    @include md-width {
        background-size: 100%;
    }
}

.container-fluid {
    padding-left: 80px;
    padding-right: 80px;
}

.container {
    max-width: 1280px;
    padding-left: 80px;
    padding-right: 80px;
    width: 100%;
    @include lg-width {
        max-width: 100%;
        padding-left: 40px;
        padding-right: 40px;
    }
}

.body-padding {
    padding-top: 128px;
    @include lg-width {
        padding-top: 64px;
    }
}

header {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.3s;
    height: 128px;
    @include lg-width {
        height: 64px;
    }
    .FH-logo a {
        display: block;
    }
    .FH-logo img {
        height: 128px;
        transition: height 0.3s;
        @include lg-width {
            height: 64px;
        }
    }
    &::after {
        background: url(assets/images/top_shadow.png);
        background-repeat: no-repeat;
        background-position: top center;
        height: 30px;
        width: 100%;
        content: '';
        display: block;
    }
    &.scroll-up {
        height: 64px;
        .FH-logo img {
            height: 64px;
            @include lg-width {
                height: 64px;
            }
        }
        @include lg-width {
            height: 64px;
        }
        .nav-item {
            &.nav-icons {
                display: none;
                @include lg-width {
                    display: flex;
                }
            }
        }
    }
    .top-nav {
        height: 100%;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: 0.3s;
        @include lg-width {
            flex-wrap: wrap;
        }
        nav {
            width: 100%;
        }
        .nav {
            justify-content: flex-end;
        }
        .nav-item {
            display: flex;
            align-items: center;
            &.nav-icons {
                width: 100%;
                justify-content: flex-end;
                padding-left: 40px;
                a {
                    width: unset;
                }
            }
            .nav-link {
                padding: 0.25rem 0.5rem;
                color: $_link_color;
                span.menu-arrow {
                    position: absolute;
                    right: 44px;
                    transition: 0.3s;
                    font-size: 1.15rem;
                }
                &:hover {
                    color: #0c4f91;
                }
                &.expanded {
                    span.menu-arrow i {
                        transform: rotate(180deg);
                    }
                }
            }
        }
        .icon {
            padding-right: 5px;
            padding-left: 5px;
            line-height: 14px !important;
            &.ig,
            &.youtube {
                font-size: 1.3rem;
            }
        }
        .lang {
            padding-right: 14px;
            padding-left: 14px;
            line-height: 14px !important;
        }
        .burger-icon {
            font-size: 1.8rem;
            color: #003366;
        }
        .subnav {
            position: absolute;
            background-color: #d7e6fd;
            color: #0d1345;
            left: 0;
            top: 35px;
            min-width: 200px;
            padding: 0px 12px;
            @include lg-width {}
            .nav-item {
                padding: 20px 0px;
                width: 100%;
                border-bottom: 1px solid #ffffff;
                @include lg-width {}
            }
        }
        @include lg-width {
            /* for mobile menu */
            .nav-menu {
                position: absolute;
                top: 64px;
                background-color: #ffffff;
                width: 100%;
                left: 0;
                border-top: 1px solid #e0e0e0;
                overflow-y: scroll;
                -webkit-overflow-scrolling: touch;
                max-height: calc(100vh - 64px);
            }
            .nav-item {
                width: 100%;
                flex-direction: column;
                &.nav-icons {
                    justify-content: flex-start;
                    flex-direction: row;
                    .nav-link {
                        padding: 20px 10px 20px 10px;
                    }
                }
                .nav-link {
                    width: 100%;
                    padding: 20px 40px 20px 40px;
                    border-bottom: 1px solid #f6f6f6;
                }
            }
            .subnav {
                position: relative;
                padding-left: 0;
                width: 100%;
                left: 0;
                top: 0;
                .nav-item {
                    border: none;
                    padding: 0;
                }
            }
        }
    }
}

.home-container {
    width: 100%;
    overflow-x: hidden;
}

.home-message {
    z-index: 1;
    transform: translateY(-50%);
    position: absolute;
    width: 60%;
    right: 0;
    @include lg-width {
        position: relative;
        transform: translateY(0);
        width: 100%;
    }
    .message-box {
        display: flex;
        flex-wrap: wrap;
        .message-box-left {
            flex: 1 1;
            padding: 80px;
            @include md-width {
                padding: 40px;
            }
        }
        &:after {
            content: '';
            background: url(assets/images/blue-bg-right.jpg);
            background-repeat: no-repeat;
            background-position: top right;
            background-color: #0e125b;
            flex: 0 0 60px;
            @include md-width {
                flex: 0 0 40px;
            }
        }
    }
    h1 {
        color: $_fh_color_blue;
        font-size: 3.1rem;
        font-weight: lighter;
        margin-bottom: 30px;
        @include md-width {
            font-size: 2.15rem;
        }
    }
    h2 {
        color: #333;
        font-size: 1.3rem;
        display: inline-block;
        margin-bottom: 0;
    }
    .very-long-dash {
        height: 2px;
        background-color: #88a3cb;
        width: 36px;
        margin-right: 24px;
        display: inline-block;
        @include md-width {
            width: 18px;
            margin-right: 18px;
        }
    }
}

.about-FH-remodal {
    background-color: #f7f7f7;
    padding-bottom: 130px;
    @include md-width {
        padding-bottom: 40px;
    }
    & * {
        transition: 0.3s;
    }
    .message {
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.8);
        color: #0c125e;
        font-style: italic;
        width: 200px;
        height: 170px;
        padding: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0 !important;
        @include lg-width {
            width: 150px;
            height: 125px;
            padding: 20px;
            line-height: 1.2;
        }
    }
    .image-select {
        margin-left: -40px;
        position: absolute;
        bottom: 60px;
        @include lg-width {
            margin-left: -60px;
        }
        @include md-width {
            margin-left: 0;
            position: relative;
            bottom: 0;
        }
        .select-item {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
                @include lg-width {
                    margin-bottom: 30px;
                }
                @include md-width {
                    margin-bottom: 10px;
                }
            }
            @include lg-width {
                margin-bottom: 10px;
            }
            .very-long-dash {
                background-color: transparent;
                height: 2px;
                width: 200px;
                margin-right: 24px;
                display: inline-block;
                @include lg-width {
                    width: 120px;
                }
                @include md-width {
                    width: 140px;
                }
            }
            .image {
                width: 72px;
                height: 72px;
                border-radius: 100%;
                overflow: hidden;
                margin-right: 10px;
                border: 1px solid #fff;
            }
            &.active {
                display: flex;
                align-items: center;
                .very-long-dash {
                    height: 2px;
                    background-color: #0d2988;
                    width: 150px;
                    @include lg-width {
                        width: 90px;
                    }
                    @include md-width {
                        width: 90px;
                    }
                }
                .image {
                    border: 3px solid #fff;
                }
            }
        }
    }
    h2 {
        color: #395fa0;
        font-size: 1.3rem;
        display: inline-block;
        margin-bottom: 0;
        @include md-width {
            font-size: 1rem;
        }
    }
}

.video {
    padding-left: 80px;
    @include lg-width {
        padding-left: 40px;
    }
    @include md-width {
        padding-left: 0;
    }
    .video-background {
        width: 100%;
        min-width: 100%;
        overflow: hidden;
        @include lg-width {
            min-width: 1024px;
        }
    }
    .youtube {
        position: absolute;
        right: 80px;
        width: 560px;
        height: 315px;
        top: 50%;
        transform: translateY(-50%);
        @include lg-width {
            right: 40px;
        }
        @include md-width {
            width: 90%;
            min-width: 320px;
            padding-bottom: 56.25%;
            height: auto;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .youtube-video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @include lg-width {
                width: 100%;
                height: 100%;
            }
        }
    }
    .youtube-content {
        width: 560px;
        background-color: #ffffff;
        position: relative;
        margin-right: 80px;
        margin-left: auto;
        padding: 40px 60px;
        margin-top: -54px;
        @include lg-width {
            margin-right: 40px;
            margin-top: -64px;
        }
        @include md-width {
            width: 100%;
            margin-left: 20px;
            margin-right: 20px;
            margin-top: -60px;
            padding: 20px 20px;
        }
    }
    h1 {
        font-size: 2.6rem;
        padding-bottom: 30px;
        border-bottom: 1px solid #88a3cb;
        display: inline-block;
    }
    h2 * {
        font-size: 1.8rem;
        color: #333333;
        font-weight: lighter;
        padding-top: 30px;
        @include md-width {
            font-size: 1.4rem;
        }
    }
}

.specialOffer {
    padding-right: 80px;
    padding-left: 80px;
    margin-top: 80px;
    @include lg-width {
        padding-right: 40px;
        padding-left: 40px;
    }
    .very-long-dash {
        height: 2px;
        background-color: #88a3cb;
        width: 36px;
        margin-right: 15px;
        display: inline-block;
    }
    h1 {
        color: #333333;
        font-size: 1.8rem;
        display: inline-block;
        font-weight: lighter;
        margin-bottom: 0;
    }
    .content {
        padding-right: 30px;
        position: relative;
        height: 100%;
        @include md-width {
            height: auto;
        }
        h2 {
            color: #000000;
            font-size: 1.3rem;
            margin-bottom: 20px;
            @include md-width {
                margin-top: 10px;
            }
        }
        h3 {
            color: #333333;
            font-size: 1rem;
            margin-bottom: 20px;
            margin-top: 35px;
            @include md-width {
                margin-top: 10px;
            }
        }
        p {
            color: #333333;
            @include md-width {
                margin-top: 10px;
            }
        }
        .specialOffer-img-box {
            position: relative;
            padding-top: 57%;
            overflow: hidden;
            margin-right: -30px;
            img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                object-fit: cover;
            }
        }
        a {
            position: absolute;
            bottom: 0;
            @include md-width {
                position: relative;
                bottom: auto;
            }
        }
    }
}

.enquiry {
    background: url(assets/images/enquiry_banner.jpg);
    padding: 80px;
    background-repeat: no-repeat;
    background-size: cover;
    @include lg-width {
        padding: 40px;
    }
    .message-box {
        background-color: rgba(255, 255, 255, 0.8);
        padding: 80px 50px;
        position: relative;
        width: 50%;
        @include lg-width {
            padding: 40px;
            width: auto;
        }
    }
    .very-long-dash {
        height: 2px;
        background-color: #88a3cb;
        width: 36px;
        margin-right: 15px;
        display: inline-block;
        @include md-width {
            display: none;
        }
    }
    h1 {
        color: #333333;
        font-size: 2.6rem;
        display: inline-block;
        font-weight: lighter;
        margin-bottom: 0;
        @include md-width {
            font-size: 1.8rem;
        }
    }
    p {
        color: #333333;
        margin-bottom: 0;
        margin-top: 20px;
        margin-left: 52px;
        @include md-width {
            margin-left: 0;
        }
    }
}

footer {
    background-color: #8ba3c7;
    color: #fff;
    .first-row {
        flex: 3 3;
    }
    .second-row {
        flex: 5 5;
    }
    .first-row,
    .second-row {
        padding-right: 40px;
        padding-left: 0;
        flex: 1 1 50%;
        @include lg-width {
            flex-basis: 50%;
            padding-right: 0;
        }
        @include xs-width {
            flex-basis: 100%;
        }
        i {
            font-size: 18px;
        }
    }
    .third-row {
        flex-basis: 340px;
        @include lg-width {
            flex-basis: 100%;
            margin-bottom: 20px;
        }
    }
    .icon {
        line-height: 16px !important;
        &.ig,
        &.youtube {
            font-size: 1.3rem;
        }
    }
    i {
        padding-right: 8px;
    }
    a {
        color: #fff;
        text-decoration: none;
        display: inline-block;
        padding: 8px 0;
        padding-left: 0;
        @include md-width {
            padding: 5px 0;
        }
        &.icon {
            padding-bottom: 0;
        }
        &:hover {
            text-decoration: none;
        }
    }
    ul {
        padding-left: 18px;
    }
    li {
        padding-left: 0;
        a {
            padding: 8px 0;
            display: block;
        }
    }
    .copy {
        padding-top: 1rem;
        padding-left: 0;
        padding-right: 0;
        font-size: 1rem;
        letter-spacing: 0 !important;
        @include xs-width {
            margin-top: 40px;
        }
        a {
            &:hover {
                color: #fff;
            }
        }
    }
    .mobile-social-icons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 40px;
    }
}

.top-kv {
    display: block;
    position: relative;
    img {
        width: 100%;
        display: block;
        min-height: 420px;
        object-fit: cover;
        @include lg-width {
            min-height: 300px;
        }
    }
}

.home-kv {
    @include xs-width {
        min-width: 200%;
        transform: translate(-25%)
    }
    *:focus {
        outline: 0;
    }
    .slick-dotted.slick-slider {
        margin-bottom: 0 !important;
    }
    .slick-dots {
        bottom: 240px !important;
        @include lg-width {
            bottom: 20px !important;
        }
        li button:before {
            font-size: 12px !important;
            color: #0c4f91 !important;
            transition: 0.2s;
        }
    }
    .slick-slide {
        position: relative;
    }
    .text {
        position: absolute;
        left: 22%;
        top: 26%;
        @include xs-width {
            left: 25%;
            width: 50%;
            right: 25%;
            top: 0;
            bottom: 0;
            z-index: 30;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 40px;
            padding-top: 35%;
        }
        .eng {
            display: block;
            line-height: 1.1 !important;
            font-size: 4.0625vw; //max 52px
            color: #0f1245;
            @media screen and (min-width: 1280px) {
                font-size: 3.714rem;
            }
            @media screen and (max-width: 767px) {
                font-size: 2.4rem;
            }
        }
        .cht {
            color: #395fa0;
            font-size: 1.3rem; //max 18px
            line-height: 1.1 !important;
            margin-top: 5px;
            display: block;
            @media screen and (min-width: 1280px) {
                font-size: 1.3rem;
                text-align: left;
            }
            @media screen and (max-width: 767px) {
                font-size: 1.1rem;
                letter-spacing: 0px !important;
                text-align: left;
            }
        }
        .link {
            font-size: 1rem; //max 18px
            line-height: 1.1 !important;
            margin-top: 25px;
            display: inline-block;
            color: #395fa0;
            border: 1px solid #7096D8;
            border-radius: 15px;
            width: auto;
            padding:7px 24px;
            cursor: pointer;
            transition: 0.3s;
            @media screen and (min-width: 1280px) {
                font-size: 1rem;
                text-align: left;
            }
            @media screen and (max-width: 767px) {
                font-size: 1rem;
                letter-spacing: 0px !important;
                text-align: left;
            }
            &:hover {
                background-color:rgba(255, 255, 255, 0.3)
            }
            i {
                font-size: 0.8rem;
                color: #174086;
            }
        }
    }
}

.inner-header {
    z-index: 1;
    position: relative;
    margin-left: 80px;
    margin-top: -266px;
    padding: 100px 80px;
    color: #333333;
    min-height: 320px;
    @include lg-width {
        margin-top: -183px;
        margin-left: 40px;
        padding: 50px 60px;
        min-height: 240px;
    }
    @include md-width {
        padding: 40px 20px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @include md-width {
            justify-content: center;
        }
    }
    h1 {
        font-size: 2.6rem;
        font-weight: lighter;
        margin-bottom: 0;
        text-align: left;
        @include md-width {
            font-size: 1.8rem;
        }
    }
    h2 {
        font-size: 1.15rem;
        margin-bottom: 0;
        padding-left: 55px;
        @include md-width {
            padding-left: 0;
            text-align: center;
            margin-bottom: 40px;
        }
    }
    h3 {
        color: #89a4cb;
        font-size: 1.3rem;
    }
    .very-long-dash {
        width: 36px;
        margin-right: 15px;
        display: inline-block;
        height: 2px;
        background-color: #88a3cb;
        @include md-width {
            display: none;
        }
    }
    &.contact-us {
        position: absolute;
        left: 0;
        right: 0;
    }
    &.about-us {
        .flora-profile {
            position: absolute;
            width: 90%;
            @include md-width {
                position: relative;
                padding-bottom: 40px;
            }
        }
    }
}

.about-us-flora {
    position: relative;
    padding: 80px 120px 80px 200px;
    @include lg-width {
        padding-left: 100px;
        padding-right: 60px;
    }
    @include md-width {
        padding: 40px 40px;
    }
    h3 {
        color: #89a4cb;
        font-size: 1.3rem;
    }
}

.quote-message {
    padding: 80px 200px;
    background-color: #ffffff;
    @include lg-width {
        padding: 60px 110px;
        background-color: #ffffff;
    }
    @include md-width {
        padding: 40px;
    }
    &::before {
        content: url('assets/images/quote-FH-logo.jpg');
        width: 100%;
        text-align: center;
        display: inline-block;
        margin: 0 auto;
        padding-bottom: 40px;
    }
    .message {
        &::before {
            content: url('assets/images/icon-quote-start.jpg');
            position: absolute;
            left: -50px;
            top: -50px;
            @include md-width {
                left: -20px;
                top: -20px;
            }
        }
        &::after {
            content: url('assets/images/icon-quote-end.jpg');
            position: absolute;
            right: -50px;
            bottom: 50px;
            @include md-width {
                right: -20px;
                bottom: 20px;
            }
        }
    }
    h1 {
        color: #333333;
        font-weight: lighter;
        font-size: 1.43rem;
        text-align: center;
        z-index: 3;
        position: relative;
        line-height: 1.5;
        @include md-width {
            font-size: 1.23rem;
        }
    }
    h2 {
        color: #0c125e;
        text-align: center;
        font-style: italic;
        margin-top: 60px;
        position: relative;
        font-size: 1rem;
    }
}

.postnatal-problem {
    position: relative;
    height: auto;
    padding-top: 80px;
    @include lg-width {
        padding-top: 40px;
    }
    .message-box {
        padding: 60px 0 60px 80px;
        margin-left: 80px;
        margin-right: 80px;
        margin-bottom: 80px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        @include lg-width {
            padding: 40px 0 40px 60px;
            margin-left: 40px;
            margin-right: 40px;
            margin-bottom: 40px;
        }
        @include md-width {
            padding: 40px 20px;
            margin-left: 20px;
            margin-right: 20px;
        }
    }
    h1 {
        font-size: 2.286rem;
        font-weight: lighter;
        color: #333333;
        margin-bottom: 40px;
        @include md-width {
            font-size: 1.8rem;
        }
    }
    .three-steps {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        @include md-width {
            flex-direction: column;
            flex-wrap: nowrap;
        }
    }
    .step-item {
        position: relative;
        margin-right: 2%;
        margin-bottom: 0;
        cursor: pointer;
        flex: 0 0 32%;
        &:hover {
            cursor: pointer;
        }
        @include md-width {
            margin-right: 0;
        }
    }
    .step-name {
        position: absolute;
        right: 15px;
        top: 15px;
        color: #fff;
        font-size: 1.3rem;
        font-weight: bold;
    }
    .selector {
        display: block;
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        @include md-width {
            display: none;
        }
    }
    .question {
        @include md-width {
            width: 100%;
        }
    }
    .answer {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        flex: 0 0 100%;
        margin-bottom: 0;
        height: auto;
        @include md-width {
            margin-top: 0;
            flex-direction: column;
            margin-bottom: 40px;
        }
        .img-box {
            width: 400px;
            object-fit: cover;
            @include md-width {
                width: 100%;
            }
        }
        span {
            max-width: 500px;
            padding: 0 20px;
            margin: 0;
            display: flex;
            align-items: center;
            @include md-width {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

.fundamental {
    .message-box {
        margin-left: 80px;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 60%;
        padding: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include md-width {
            padding: 40px;
            margin-right: 40px;
            margin-left: 40px;
            width: auto;
        }
    }
    .fundamental-img {
        object-fit: cover;
        object-position: left;
        width: 100%;
        @include md-width {
            height: 400px;
        }
    }
    h1 {
        font-size: 2.286rem;
        font-weight: lighter;
        color: #333333;
        margin-bottom: 20px;
        @include md-width {
            font-size: 1.8rem;
        }
    }
}

.FAQ {
    background-color: rgba(198, 214, 239, 0.4);
    padding: 100px 220px;
    @include lg-width {
        padding: 60px 80px;
    }
    @include md-width {
        padding: 40px;
    }
    h1 {
        font-size: 2.286rem;
        font-weight: lighter;
        color: #333333;
        margin-bottom: 20px;
        @include md-width {
            font-size: 1.8rem;
        }
    }
    .question {
        display: flex;
        position: relative;
        cursor: pointer;
        border-bottom: 1px solid #E8EFF8;
        min-height: 60px;
        @include md-width {
            min-height: 40px;
        }
        label {
            flex: 0 0 60px;
            background-color: #8ba3c7;
            color: #fff;
            font-size: 1.8rem;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0;
            min-height: 100%;
            @include md-width {
                flex-basis: 40px;
            }
        }
        div {
            flex: 1 1;
            background-color: #fff;
            display: flex;
            align-items: center;
            font-size: 1.15rem;
            color: #0f1245;
            padding-left: 20px;
            min-height: 100%;
        }
        span {
            flex: 0 0 60px;
            background-color: #fff;
            display: inline-block;
            color: #c6d6ef;
            font-size: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 100%;
            @include md-width {
                flex-basis: 40px;
                font-size: 1.3rem;
            }
        }
    }
    .item {
        margin-bottom: 20px;
        overflow: hidden;
        i {
            transition: 0.3s;
        }
        &.active i {
            transform: rotate(180deg);
        }
    }
    .answer {
        background-color: #fff;
        font-size: 1.15rem;
        color: #0f1245;
        padding: 0 20px;
        padding-right: 80px;
        margin-left: 60px;
        transition: 0.4s;
        max-height: 250px;
        &.hide {
            transition: 0.3s;
            max-height: 0;
        }
        p {
            padding: 20px 0;
            &:last-child {
                margin-bottom: 0;
            }
        }
        @include lg-width {
            max-height: 400px;
        }
        @include md-width {
            max-height: 600px;
            margin-left: 40px;
        }
    }
}

.feature-table {
    background-color: rgba(217, 228, 247, 0.4);
    padding: 100px 220px;
    @include lg-width {
        padding: 60px 80px;
    }
    @include md-width {
        padding: 40px;
    }
    h1 {
        font-size: 2.286rem;
        font-weight: lighter;
        color: #333333;
        margin-bottom: 20px;
        @include md-width {
            font-size: 1.8rem;
        }
    }
    .table-row {
        display: flex;
        flex-wrap: nowrap;
        border-bottom: 1px solid transparent;
    }
    .table-header {
        display: none;
        dt {
            border: none;
            background-color: transparent;
        }
        dd {
            background-color: #8ba3c7;
        }
        span {
            background-color: #eaeaea;
        }
    }
    dt {
        border-left: 7px solid #8ba3c7;
        color: #8ba3c7;
        background-color: #fff;
        flex: 0 0 30%;
        padding: 20px;
        display: flex;
        align-items: center;
        font-weight: lighter;
        word-wrap: break-word;
        @include md-width {
            flex: 0 0 30%;
            padding: 5px;
        }
        @media (max-width: 500px) {
            flex: 0 0 40%;
        }
        img {
            margin-right: 5px;
        }
    }
    dd {
        color: #fff;
        background-color: #95aacb;
        flex: 0 0 70%;
        margin-bottom: 0;
        padding: 20px;
        display: flex;
        align-items: center;
        word-wrap: break-word;
        @include md-width {
            flex: 0 0 70%;
            padding: 5px;
        }
        @media (max-width: 500px) {
            flex: 0 0 60%;
        }
    }
    span {
        display: none;
        color: #333333;
        background-color: #fff;
        flex: 0 0 30%;
        padding: 20px;
        align-items: center;
        word-wrap: break-word;
        @include md-width {
            flex: 0 0 40%;
            padding: 5px;
        }
        @media (max-width: 500px) {
            display: none;
        }
    }
}

.treatment-item {
    padding: 80px;
    @include lg-width {
        padding: 40px;
    }
    h1 {
        font-size: 2.286rem;
        font-weight: lighter;
        color: #333333;
        margin-bottom: 20px;
        @include lg-width {
            font-size: 1.8rem;
        }
    }
    .item {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        @include md-width {
            max-width: 50%;
            min-width: 320px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .title {
        position: absolute;
        font-size: 1.15rem;
        color: #fff;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 20%;
        width: 60%;
        text-align: center;
        font-weight: bold;
        z-index: 3;
    }
}

.body-binding-step {
    padding: 80px;
    @include lg-width {
        padding: 40px;
        padding-top: 80px;
    }
    @include md-width {
        padding: 40px;
    }
    .intro {
        padding-left: 55px;
        padding-right: 55px;
        margin-bottom: 50px;
        @include md-width {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    h1 {
        font-size: 2.286rem;
        font-weight: lighter;
        color: #333333;
        margin-bottom: 20px;
        @include md-width {
            font-size: 1.8rem;
        }
    }
    .item {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        @include md-width {
            width: 50%;
            min-width: 320px;
            margin-left: auto;
            margin-right: auto;
        }
        .desc-bg {
            position: absolute;
            width: 73.5%;
            padding-top: 73.5%;
            top: 49%;
            left: 47%;
            transform: translate(-50%, -50%);
            border-radius: 100%;
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            transition: 0.3s;
        }
        span {
            position: absolute;
            width: 100%;
            padding: 10%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: transparent;
            z-index: 2;
            transition: 0.3s;
            font-size: 0.86rem;
            font-weight: bold;
            text-align: center;
        }
        &:hover {
            .desc-bg {
                background-color: rgba(139, 163, 199, 0.9);
            }
            span {
                color: #fff;
            }
        }
    }
    .title {
        position: absolute;
        font-size: 1.15rem;
        line-height: 1;
        color: #fff;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 20%;
        width: 50%;
        text-align: center;
        font-weight: bold;
        z-index: 3;
        margin-left: -10px;
    }
}

.case-study {
    position: relative;
    height: auto;
    padding-top: 80px;
    @include lg-width {
        padding-top: 40px;
    }
    .cases-container {
        width: 100%;
    }
    .case {
        .case-top {
            padding-top: 120px;
            &::after {
                content: '';
                background: url(assets/images/case_spitter.png);
                background-repeat: no-repeat;
                width: 100%;
                height: 25px;
                display: block;
                background-position: center center;
                transform: translateY(11px);
                @include md-width {
                    margin-bottom: 10px;
                }
            }
            .case-img {
                display: flex;
                img {
                    flex: 1 1;
                }
            }
        }
        &:nth-child(odd) {
            .case-top {
                margin-left: 17%;
                margin-right: 0;
                padding-right: 17%;
                @include md-width {
                    margin-left: 20px;
                    padding-top: 40px;
                    padding-right: 20px;
                }
                .case-img {
                    &::before {
                        content: '';
                        background: url(assets/images/blue-bg-right.jpg);
                        background-repeat: no-repeat;
                        background-position: top right;
                        background-color: #0e125b;
                        flex: 0 0 33px;
                    }
                }
            }
        }
        &:nth-child(even) {
            .case-top {
                padding-left: 17%;
                margin-right: 0;
                margin-right: 17%;
                @include md-width {
                    padding-left: 20px;
                    padding-top: 40px;
                    margin-right: 20px;
                }
                .case-img {
                    &::after {
                        content: '';
                        background: url(assets/images/blue-bg-right.jpg);
                        background-repeat: no-repeat;
                        background-position: top right;
                        background-color: #0e125b;
                        flex: 0 0 33px;
                    }
                }
            }
        }
        .title {
            font-size: 2.286rem;
            font-weight: lighter;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 50px 50px 35px 0;
            color: $_fh_color_blue;
            @include md-width {
                padding: 30px 20px 20px 20px;
                font-size: 1.8rem;
            }
        }
        .content {
            padding: 40px 17%;
            @include md-width {
                padding: 20px;
            }
        }
    }
}

.fh-media {
    padding: 0 80px 0 80px;
    @include lg-width {
        padding: 0 40px 0 40px;
    }
    @include md-width {
        padding: 0 40px 0 40px;
    }
    .media-filter {
        display: flex;
        justify-content: flex-end;
        margin: 40px auto;
        .filter-dropdown {
            padding: 5px 10px 0px 10px;
            border: none;
            border-bottom: 2px solid #88a3cb;
            background-color: transparent;
            font-size: 16px;
            color: #333333;
            width: 210px;
            text-align: left;
            cursor: pointer;
            .dropdown-item {
                padding: 5px 10px 0px 10px;
                font-size: 1.3rem;
                color: #333333;
                cursor: pointer;
            }
            &.dropdown-toggle::after {
                right: 0;
                position: absolute;
                top: 13px;
            }
        }
        .dropdown-toggle::after {
            color: #88a3cb;
        }
    }
    .media-item {
        margin-bottom: 80px;
        cursor: pointer;
        @include lg-width {
            margin-bottom: 40px;
        }
        &:hover {
            .img img {
                transform: scale(1.1);
            }
        }
        .img {
            overflow: hidden;
            img {
                transition: transform 0.3s;
            }
        }
        .content {
            text-align: center;
            position: relative;
            margin-top: -33px;
            margin-left: 10px;
            margin-right: 10px;
            @include md-width {
                margin-top: -20px;
                padding: 0;
            }
            .text {
                background-color: #fff;
                padding: 40px 60px;
                position: relative;
                z-index: 20;
                @include md-width {
                    padding: 20px 20px;
                }
            }
            &::after {
                content: '';
                background: url(assets/images/media_item_bg.png);
                background-repeat: no-repeat;
                width: 88px;
                height: 138px;
                position: absolute;
                right: -40px;
                top: -24px;
                z-index: 10;
            }
        }
        h1 {
            font-size: 1.3rem;
            color: $_fh_color_blue;
            margin-bottom: 20px;
        }
        h2 {
            width: 100%;
            padding-top: 30px;
            font-size: 0.86rem;
            color: $_fh_color_blue;
        }
        .date {
            margin-top: 48px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            @include md-width {
                margin-top: 24px;
            }
            &::before {
                content: '';
                height: 1px;
                width: 20%;
                background-color: $_fh_color_blue;
                display: block;
            }
        }
    }
}

.specialOffer-page {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 80px;
    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 2px;
        height: 100%;
        background-color: #8AA2C5;
        clear: both;
        top: 0;
    }
    .dot {
        height: 120px;
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            overflow: hidden;
            background-color: #8AA2C5;
            display: table;
            clear: both;
        }
    }
    .specialOffer-item {
        width: 100%;
        .message-box {
            padding: 65px 55px;
            margin-top: -120px;
            @include lg-width {
                margin-top: -80px;
            }
            @include md-width {
                margin-top: 0;
                padding: 40px;
            }
        }
        &.left {
            margin-left: 80px;
            margin-right: 20%;
            @include lg-width {
                margin-left: 40px;
            }
            @include md-width {
                margin-left: 20px;
                margin-right: 20px;
            }
            .message-box {
                margin-left: 80px;
                margin-right: 15%;
                position: relative;
                @include lg-width {
                    margin-left: 40px;
                }
                @include md-width {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
        &.right {
            margin-left: 20%;
            margin-right: 80px;
            @include lg-width {
                margin-right: 40px;
            }
            @include md-width {
                margin-left: 20px;
                margin-right: 20px;
            }
            .message-box {
                margin-left: 15%;
                margin-right: 80px;
                position: relative;
                @include lg-width {
                    margin-right: 40px;
                }
                @include md-width {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
        h1 {
            color: #333333;
            font-size: 1.8rem;
            display: inline-block;
            font-weight: lighter;
            margin-bottom: 0;
            @include md-width {
                font-size: 1.8rem;
                margin-bottom: 0;
            }
        }
        h2 {
            color: #333333;
            font-size: 2.15rem;
            font-weight: lighter;
            margin-bottom: 40px;
            margin-top: 25px;
            @include md-width {
                font-size: 1.43rem;
                margin-bottom: 20px;
            }
        }
        h3 {
            color: #333333;
            font-size: 0.86rem;
            margin-bottom: 40px;
            @include md-width {
                margin-bottom: 20px;
            }
        }
        p {
            color: #333333;
        }
    }
}

.g-map {
    width: 100%;
    height: 500px;
}

.g-map agm-map {
    height: 500px;
}

.contact-us-form {
    margin-left: 15%;
    margin-right: 15%;
    position: relative;
    padding-bottom: 80px;
    margin-top: -100px;
    @include lg-width {
        margin-left: 15%;
        margin-right: 15%;
    }
    @include md-width {
        margin-top: 0;
        margin-left: 40px;
        margin-right: 40px;
    }
    .img {
        width: 100%;
        @include md-width {
            margin-left: -40px;
            margin-right: -40px;
            min-height: 310px;
            height: 310px;
            width: unset;
        }
        img {
            width: 100%;
            @include md-width {
                object-fit: cover;
                height: 310px;
            }
        }
    }
    .message {
        padding: 36px;
        background-color: #fff;
        margin-left: 50%;
        margin-right: 24px;
        margin-top: -80px;
        position: relative;
        z-index: 1;
        @include lg-width {
            margin-left: 40px;
            margin-right: 40px;
        }
        @include md-width {
            margin-left: 0;
            margin-right: 0;
            padding: 20px;
        }
    }
    .input-form {
        padding-top: 40px;
        input,
        textarea {
            background-color: transparent;
            border: none;
            border-bottom: #999 1px solid;
            border-radius: 0;
            padding: 5px 0;
            width: 100%;
            margin-bottom: 20px;
            font-size: 1.15rem;
            @include md-width {
                font-size: 1rem;
            }
        }
        input:focus,
        textarea:focus {
            color: #000;
            background-color: transparent;
            border-bottom: 1px solid #80bdff;
            outline: none;
        }
        .button-group {
            display: flex;
            flex-wrap: wrap;
            button {
                background-color: $_fh_color_blue;
                color: #fff;
                flex: 1 1;
                margin-right: 20px;
                height: 78px;
                font-size: 1.3rem;
                border: none;
                &:focus {
                    outline: 0;
                }
                @include md-width {
                    font-size: 1rem;
                    flex-basis: 100%;
                    margin-right: 0;
                    margin-bottom: 20px;
                }
            }
            re-captcha {
                @include md-width {
                    width: 100%;
                }
            }
        }
    }
}

.price-table-set {
    padding-top: 80px;
    padding-bottom: 80px;
    @include md-width {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .price-table-container {
        &:last-child {
            price-table {
                @include md-width {
                    padding-bottom: 0;
                }
            }
        }
    }
    price-table {
        height: 100%;
        display: block;
        padding-bottom: 40px;
        @include md-width {
            padding-bottom: 40px;
            height: auto;
        }
        &::before {
            content: ' ';
            display: table;
        }
        &::after {
            content: ' ';
            display: table;
        }
    }
}

.price-table {
    padding: 30px;
    background-color: #fff;
    height: 100%;
    @include md-width {
        padding: 20px;
    }
    .content-box {
        padding: 30px;
        border: 1px solid #dcc8b4;
        position: relative;
        text-align: center;
        height: 100%;
        @include md-width {
            padding: 20px;
        }
        &::before {
            content: '';
            width: 0;
            height: 0;
            border-top: 30px solid #dcc8b4;
            border-right: 30px solid transparent;
            border-bottom: 30px solid transparent;
            position: absolute;
            top: 3px;
            left: 3px;
        }
        &::after {
            content: '';
            width: 0;
            height: 0;
            border-bottom: 30px solid #dcc8b4;
            border-left: 30px solid transparent;
            border-top: 30px solid transparent;
            position: absolute;
            bottom: 3px;
            right: 3px;
        }
    }
    .duration-text {
        font-size: 1.57rem;
        color: #8ba3c7;
        .min {
            font-size: 34px;
            color: #8ba3c7;
        }
    }
    .desc p {
        color: #999999;
        font-size: 1.3rem;
        font-weight: lighter;
        margin-bottom: 0.5rem;
        @include md-width {
            font-size: 1rem;
        }
    }
    .price-content p {
        color: #000;
        font-size: 1.57rem;
        margin-bottom: 0.5rem;
        @include md-width {
            font-size: 1.1428rem;
        }
    }
    .very-long-dash {
        height: 2px;
        background-color: #88a3cb;
        width: 36px;
        margin-right: 15px;
        display: inline-block;
        margin-top: 30px;
        margin-bottom: 30px;
        @include md-width {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
}

.price-remark {
    padding-top: 20px;
    padding-bottom: 40px;
    color: #000;
    span::after {
        content: '|';
        padding-left: 15px;
        padding-right: 15px;
    }
    span:last-child::after {
        content: '';
        padding-left: 0;
        padding-right: 0;
    }
}

.treatment-content {
    padding: 80px 0;
    @include lg-width {
        padding: 40px 80px;
    }
    @include md-width {
        padding: 40px;
    }
    h1 {
        color: #333333;
        font-size: 1.8rem;
        display: inline-block;
        font-weight: lighter;
        margin-bottom: 0;
    }
    h2 {
        color: #333333;
        font-size: 2.15rem;
        font-weight: lighter;
        margin-bottom: 40px;
        margin-top: 25px;
    }
    h3 {
        color: #89a4cb;
        font-size: 1.3rem;
        margin-bottom: 40px;
    }
    p {
        color: #333333;
        margin-bottom: 40px;
    }
}

.FHblog {
    position: relative;
    height: auto;
    margin-left: 80px;
    margin-top: 80px;
    @include lg-width {
        padding-top: 40px;
        margin-left: 40px;
        margin-top: 40px;
    }
    @include md-width {
        margin-left: 20px;
    }
    .blog-container {
        width: 100%;
        padding: 80px;
        display: flex;
        @include lg-width {
            padding: 40px;
        }
        @include md-width {
            padding: 40px 20px;
        }
        .listing {
            flex: 0 0 300px;
            @include lg-width {
                flex-basis: 160px;
            }
            @include md-width {
                position: fixed;
                width: 100%;
                left: 0;
                top: 0;
                bottom: 0;
                z-index: 9999;
                background: rgba(0, 0, 0, 0.7);
                visibility: hidden;
                &.display {
                    visibility: visible;
                    .sticky-menu {
                        left: 0;
                    }
                    @at-root .blog-container {
                        overflow: hidden;
                    }
                }
            }
            .sticky-menu {
                position: sticky;
                position: -webkit-sticky;
                top: 60px;
                @include md-width {
                    top: 0;
                    left: -100%;
                    transition: 0.3s;
                    height: 100%;
                    position: relative;
                    width: 240px;
                    background-color: #fff;
                    padding: 20px;
                    overflow-y: scroll;
                    -webkit-overflow-scrolling: touch;
                }
            }
            .flora-avatar {
                position: relative;
                width: 80%;
                padding-top: 80%;
                border-radius: 100%;
                overflow: hidden;
                margin: 0 auto;
                img {
                    width: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                }
            }
            .small-title {
                color: #333;
                text-align: center;
                font-size: 1.3rem;
                padding: 10px;
            }
            .title {
                font-size: 1.3rem;
                width: 100%;
                padding-top: 40px;
                border-bottom: 1px solid #d8d8d8;
                @include md-width {
                    padding-top: 10px;
                }
            }
            .list-item {
                display: flex;
                flex-wrap: wrap;
                padding: 10px 0;
                border-bottom: 1px dotted #d8d8d8;
                transition: background-color 0.3s;
                cursor: pointer;
                &:hover {
                    background-color: #f5f5f5;
                }
                .img {
                    flex: 1 1;
                    @include lg-width {
                        flex: 0 0 100%;
                    }
                    img {
                        width: 100%;
                    }
                }
                span {
                    flex: 1 1;
                    padding: 0 0 0 10px;
                    @include lg-width {
                        flex: 0 0 100%;
                        padding: 0 0 10px 0;
                    }
                    .date {
                        padding: 0px;
                        color: #666;
                    }
                }
            }
        }
        .blog-content {
            flex: 1 1;
            padding-left: 80px;
            width: 100%;
            @include lg-width {
                padding-left: 40px;
            }
            @include md-width {
                padding-left: 0;
            }
            .title {
                font-size: 2.286rem;
                font-weight: lighter;
                width: 100%;
                color: $_fh_color_blue;
                @include md-width {
                    padding: 30px 0 20px 0;
                    font-size: 1.8rem;
                }
            }
            .date {
                margin-top: 12px;
                width: 100%;
                padding-bottom: 40px;
            }
            .rich-text-content img {
                width: auto !important;
                max-width: 100%;
            }
        }
    }
}

.back-to-top {
    position: fixed;
    cursor: pointer;
    bottom: 30px;
    right: 45px; //border:3px solid rgba(137,164,203,0.5);
    background: rgba(255, 255, 255, 0.7);
    width: 60px;
    height: 60px;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    color: $_fh_color_blue;
    transition: 0.3s;
    -webkit-box-shadow: 7px 4px 28px -12px rgba(0, 0, 0, 0.43);
    -moz-box-shadow: 7px 4px 28px -12px rgba(0, 0, 0, 0.43);
    box-shadow: 7px 4px 28px -12px rgba(0, 0, 0, 0.43);
    z-index: 9999;
    display: flex;
    opacity: 0;
    @include md-width {
        right: 20px;
        bottom: 20px;
    }
    &.show {
        opacity: 1;
    }
    &:hover {
        text-decoration: none;
        color: $_link_hover_color;
    }
}

.blog-menu {
    position: fixed;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.7);
    width: 60px;
    height: 60px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    color: $_fh_color_blue;
    transition: color 0.3s;
    -webkit-box-shadow: 7px 4px 28px -12px rgba(0, 0, 0, 0.43);
    -moz-box-shadow: 7px 4px 28px -12px rgba(0, 0, 0, 0.43);
    box-shadow: 7px 4px 28px -12px rgba(0, 0, 0, 0.43);
    z-index: 9999;
    right: 20px;
    bottom: 90px;
    &:hover {
        text-decoration: none;
        color: $_link_hover_color;
    }
}

/* hvr cus */

.hvr-bounce-to-right:before {
    background-color: #395fa0;
}

.hvr-sweep-to-left:before {
    background-color: #395fa0;
}

.hvr-sweep-to-right:before {
    background-color: #395fa0;
}

/* call to active button */

.call-to-active-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    .arrow {
        color: #395fa0;
        height: 50px;
        width: 50px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #395fa0;
        overflow: hidden;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        transition: 0.3s;
        i {
            padding-left: 5px;
        }
        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #395fa0;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition-duration: .3s;
            transition-duration: .3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
            border-radius: 100%;
        }
    }
    &.small .arrow {
        height: 35px;
        width: 35px;
        overflow: hidden;
    }
    &:hover {
        text-decoration: none;
    }
    & .arrow &:active .arrow,
    &:focus .arrow,
    &:hover .arrow {
        color: #fff;
        overflow: hidden;
        &:before {
            -webkit-transform: scaleX(1);
            transform: scaleX(1)
        }
    }
    .text {
        font-size: 1.15rem;
        color: #395fa0;
        margin-left: 10px;
    }
}

.overflow-hide {
    overflow: hidden;
}

/* contentful rich text content style */

.rich-text-content {
    h1 {
        font-size: 2.6rem;
        @include md-width {
            font-size: 1.8rem;
        }
        font-weight: normal;
        margin: 0 0 1rem 0;
        &::before {
            content: ' ';
            display: table;
        }
        &::after {
            content: ' ';
            display: table;
        }
    }
    h2 {
        font-size: 2.15rem;
        @include md-width {
            font-size: 1.43rem;
        }
        font-weight: normal;
        margin: 0 0 1rem 0;
        &::before {
            content: ' ';
            display: table;
        }
        &::after {
            content: ' ';
            display: table;
        }
    }
    h3 {
        font-size: 1.8rem;
        @include md-width {
            font-size: 1.3rem;
        }
        font-weight: normal;
        margin: 0 0 1rem 0;
        &::before {
            content: ' ';
            display: table;
        }
        &::after {
            content: ' ';
            display: table;
        }
    }
    ol {
        padding-left: 24px;
    }
    ul {
        padding-left: 18px;
    }
    ol p,
    ul p {
        margin-bottom: 0.5rem;
        &::before {
            content: ' ';
            display: inline;
        }
        &::after {
            content: ' ';
            display: inline;
        }
    }
    p {
        margin-bottom: 1rem;
        &::before {
            content: ' ';
            display: table;
        }
        &::after {
            content: ' ';
            display: table;
        }
    }
    img {
        width: 100%;
        margin-bottom: 14px;
    }
}

/* preloader */

/* The Loader */

#loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    overflow: hidden;
}

.no-js #loader-wrapper {
    display: none;
}

#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #C3D1E4;
    -webkit-animation: spin 1.7s linear infinite;
    animation: spin 1.7s linear infinite;
    z-index: 11;
    &:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #8793C8;
        -webkit-animation: spin-reverse 0.6s linear infinite;
        animation: spin-reverse 0.6s linear infinite;
    }
    &:after {
        content: "";
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #3E67B3;
        -webkit-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes spin-reverse {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(-360deg);
    }
}

@keyframes spin-reverse {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

#loader-wrapper .loader-section {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: #F7F7F7;
    z-index: 10;
}

#loader {
    opacity: 1;
    transition: all 0.3s 0.3s ease-out;
}

#loader-wrapper {
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    transition: all 0.5s ease-out;
    .loader-section {
        animation: colorchange 3s infinite;
        -webkit-animation: colorchange 3s infinite;
        @keyframes colorchange {
            0% {
                background: #F7F7F7;
            }
            50% {
                background: #EEF5FD;
            }
            0% {
                background: #F7F7F7;
            }
        }
        @-webkit-keyframes colorchange {
            0% {
                background: #F7F7F7;
            }
            50% {
                background: #EEF5FD;
            }
            0% {
                background: #F7F7F7;
            }
        }
    }
}

.loaded #loader {
    opacity: 0;
    transition: all 0.3s ease-out;
}

.loaded #loader-wrapper {
    visibility: hidden;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    transition: all 0.3s 0.7s ease-out;
}